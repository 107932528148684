// React
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
// Third Party
import moment from "moment";

import { setStorageItem } from "./applicationStorage";

// Table Handlers
export const handleTableCopyId = (id) => () => {
    copyToClipboard(id);
    return;
};

export const filterEmptyObjects = (arr) => {
    return arr?.filter(f => !isEmpty(f));
};
// Conversations
export const mapConversations = (data) => {
    if (data) {
        return filterEmptyObjects(data).map((value) => {           
            value.id= uuidv4();
            value.conversation_start_ts = new Date(value.conversation_start_ts).getTime()
            value.conversation_end_ts = new Date(value.conversation_end_ts).getTime()          
            if (!value.division)
            {
                value.division = 'No Division'
            }
            return value;
        });
    }
};
// recordings
export const mapRecordings = (data) => {
    if (data) {
        return filterEmptyObjects(data).map((value) => {           
            value.id= uuidv4();
            return value;
        });
    }
};

 

// audits
export const mapAudits = (data) => {
    if (data) {
        return filterEmptyObjects(data).map((value) => {           
            value.id= uuidv4();
            return value;
        });
    }
};

// downloads
export const mapDownloads = (data) => {
    if (data) {
        return filterEmptyObjects(data).map((value) => {           
            value.id= uuidv4();
            return value;
        });
    }
};
export const handleTableFilterChange = (model, details, tablePreferences, setterTablePreferences, tablePrefId) => {
    if (model && tablePreferences) {
        if (tablePreferences.filter && tablePreferences.filter.filterModel && model.items) {
            const newItems = {
                filter: {
                    filterModel: {
                        items: [
                            ...model.items
                        ]
                    }
                }
            };
            const newTablePref = {
                ...tablePreferences,
                ...newItems
            };
            setterTablePreferences(newTablePref);
            setStorageItem(tablePrefId, newTablePref);
        }
    }
};

export const handleTableSortingChange = (model, details, tablePreferences, setterTablePreferences, tablePrefId) => {
    if (model && tablePreferences) {
        if (tablePreferences.sorting && tablePreferences.sorting.sortModel) {
            const newItems = {
                sorting: {
                    sortModel: [...model]
                }
            };
            const newTablePref = {
                ...tablePreferences,
                ...newItems
            };
            setterTablePreferences(newTablePref);
            setStorageItem(tablePrefId, newTablePref);
        }
    }
};

export const handleTableColumnVisibilityChange = (model, details, tablePreferences, setterTablePreferences, tablePrefId) => {
    if (model && tablePreferences) {
        if (tablePreferences.columns && tablePreferences.columns.columnVisibilityModel) {
            const newItems = {
                columns: {
                    columnVisibilityModel: {
                        ...model
                    }
                }
            };
            const newTablePref = {
                ...tablePreferences,
                ...newItems
            };
            setterTablePreferences(newTablePref);
            setStorageItem(tablePrefId, newTablePref);
        }
    }
};

export const handleTablePaginationChange = (model, details, tablePreferences, setterTablePreferences, tablePrefId) => {
    if (model && tablePreferences) {
        if (tablePreferences.pagination && tablePreferences.pagination.paginationModel) {
            const newItems = {
                pagination: {
                    paginationModel: {
                        ...model
                    }
                }
            };
            const newTablePref = {
                ...tablePreferences,
                ...newItems
            };
            setterTablePreferences(newTablePref);
            setStorageItem(tablePrefId, newTablePref);
        }
    }
};

 

export default {
    handleTableCopyId,
    handleTableColumnVisibilityChange,
    handleTableFilterChange,
    handleTableSortingChange,
    handleTablePaginationChange,
    mapConversations,
    mapRecordings,
    mapDownloads
}