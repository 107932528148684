import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ConversationsSearchCriteria from "../conversationsSearchCriteria/ConversationsSearchCriteria";
import IPFXConversationsSearchCriteria from "../ipfxConversationSSearchCriteria/IPFXConversationsSearchCriteria";
import backend from "../../services/backend";
import AlertMessage from "../alertMessage/AlertMessage";
import WaitingModal from "../Modals/waitingModal/WaitingModal";
import AlertModal from "../Modals/alertModal/AlertModal";
import ConfirmationModal from "../Modals/confirmationModal/ConfirmationModal";
import constants from "../../constants";
import moment from "moment-timezone";
import utils from "../../services/utils";
import Tooltip from '@mui/material/Tooltip';
import "./Home.css";
import {
  DataGrid,
  GridActionsCellItem,
  useGridApiRef,
  GridToolbar,
  getGridStringOperators,
  GridPreferencePanelsValue,
} from "@mui/x-data-grid";
import {
  dateOperators,
  notContainOperator,
  rangeOperator,
} from "../common/FilterOperators";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  mapConversations,
  mapRecordings,
} from "../../components/utilities/table-utilities";
import RecordingDialog from "../conversationDetails/RecordingDialog";
import RecordingModel from "../conversationDetails/RecordingModel";
import { Info } from "@mui/icons-material";
import IpfxRecordingPlaybackModal from "../Modals/ipfxRecordingPlaybackModal/IpfxRecordingPlaybackModal";
import TableToolbar from "../../components/table-toolbar/TableToolbar";
import CopyAllRounded from "@mui/icons-material/CopyAllRounded";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconButton } from "@mui/material";

const pageSize = sessionStorage.getItem(
  constants.SESSION_KEY_CONVERSATIONS_PAGESIZE
)
  ? parseInt(
      sessionStorage.getItem(constants.SESSION_KEY_CONVERSATIONS_PAGESIZE)
    )
  : 15;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ p: 1 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Home = (props) => {
  const isMounted = React.useRef(true);
  const gridRef = useGridApiRef();
  const ipfxGridRef = useGridApiRef();

  const [error, setError] = useState();
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [ipfxConversations, setIpfxConversations] = useState([]);
  const [agents, setAgents] = useState([]);
  const [user, setUser] = useState(undefined);
  const [message, setMessage] = useState();
  const [role, setRole] = useState();
  const [searchAlertVisible, setSearchAlertVisible] = useState(false);
  const [searchAlertMessage, setSearchAlertMessage] = useState();
  const [columns, setColumns] = useState([]);
  const [waitingHeader, setWaitingHeader] = useState();
  const history = useHistory();
  const tablePrefId = "conversations";
  const ipfxTablePrefId = "ipfxconversations";
  // Tooltips
  //const [searchConversationsTooltipOpen, setSearchConversationsTooltipOpen] = useState(false)
  const [hideColumnsTooltipOpen, setHideColumnsTooltipOpen] = useState(false);
  const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false);
  const [preFetchedBlob, setPreFetchedBlob] = useState(null);
  // Collapsable
  const [isColumnsSettingsOpen, setIsColumnsSettingsOpen] = useState(false);
  const [
    isConversationsSearchCriteriaOpen,
    setIsConversationsSearchCriteriaOpen,
  ] = useState(true);

  //columns to display
  const [columnsToDisplay, setColumnsToDisplay] = useState();
  const [exportTooltipOpen, setExportTooltipOpen] = useState(false);

  const [viewRecordingModal, setViewRecordingModal] = React.useState(false);
  const [fields, setFields] = React.useState(
    JSON.parse(JSON.stringify(RecordingModel))
  );
  const [conversation, setConversation] = React.useState();
  const [recordings, setRecordings] = useState([]);
  const [value, setValue] = React.useState(0);
  const [recordingPlayback, setRecordingPlayback] = useState();
  const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] =
    useState(false);

  const [isIPFXUser, setIPFXUser] = useState(false);
  const [isVerintUser, setVerintUser] = useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [recordingURL, setRecordingURL] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const exportConversation = async (searchCriteria) => {
    console.log(
      `Loading conversations : exportConversation # ${JSON.stringify(
        searchCriteria
      )}`
    );
    try {
      setWaitingHeader("Loading export conversation");
      setIsWaitingModalOpen(true);
      console.log(JSON.stringify(searchCriteria));
      if (role != "admin") {
        console.log(
          `Search Criteria before submitting is ${JSON.stringify(
            searchCriteria
          )}`
        );

        if (searchCriteria.division && searchCriteria.division == "*") {
          console.log(user.authorizedDivisions);
          let filteredDivision = user.divisions.filter((x) =>
            user.authorizedDivisions.includes(x.value)
          );
          console.log(filteredDivision);
          filteredDivision = filteredDivision.map((a) => a.value);
          console.log(filteredDivision);
          searchCriteria.division = filteredDivision.toString();
          console.log(
            `Fix to search criteria with Division ${JSON.stringify(
              searchCriteria
            )}`
          );
        }
      }
      const response = await backend.exportConversations(
        sessionStorage.getItem(constants.GC_TOKEN),
        searchCriteria
      );

      console.log("fetchData.response:", JSON.stringify(response));
      setIsWaitingModalOpen(false);
      setMessage(
        `Export Request is submitted! \n , please check download page for status of export`
      );
      setTimeout(() => {
        history.push("/downloads");
      }, 2000);
    } catch (error) {
      setError(
        `An error occured while fetching data:${JSON.stringify(error.message)}`
      );
      console.log(error);
    } finally {
      setIsWaitingModalOpen(false);
    }
  };

  const exportIPFXConversation = async (searchCriteria) => {
    console.log(
      `Loading conversations : exportConversation # ${JSON.stringify(
        searchCriteria
      )}`
    );
    try {
      setWaitingHeader("Loading export conversation");
      setIsWaitingModalOpen(true);
      console.log(JSON.stringify(searchCriteria));

      const response = await backend.exportIPFXConversations(
        sessionStorage.getItem(constants.GC_TOKEN),
        searchCriteria
      );

      console.log("fetchData.response:", JSON.stringify(response));
      setIsWaitingModalOpen(false);
      setMessage(
        `Export Request is submitted! \n , please check download page for status of export`
      );
      setTimeout(() => {
        history.push("/downloads");
      }, 2000);
    } catch (error) {
      setError(
        `An error occured while fetching data:${JSON.stringify(error.message)}`
      );
      console.log(error);
    } finally {
      setIsWaitingModalOpen(false);
    }
  };

  const searchConversation = async (searchCriteria) => {
    try {
      setWaitingHeader("Retrieving Conversation Data");
      setIsWaitingModalOpen(true);

      console.log(
        `Executing search with criteria: ${JSON.stringify(searchCriteria)}`
      );

      const response = await backend.getConversations(
        sessionStorage.getItem(constants.GC_TOKEN),
        searchCriteria
      );

      setSearchAlertVisible(
        response.records_returned === 0 ||
          response.records_returned < response.total_records
      );
      setSearchAlertMessage(
        response.records_returned === 0
          ? "No records found. Please refine your search criteria."
          : `Displaying ${response.records_returned} of ${response.total_records} conversations. Refine search for specific results.`
      );

      setConversations(mapConversations(response.conversations));
    } catch (error) {
      setError(`Error Fetching Conversations: ${error.message}`);
      console.error(error);
    } finally {
      setIsWaitingModalOpen(false);
    }
  };

  const searchIPFXConversations = async (searchCriteria) => {
    console.log(
      `Loading conversations : searchConversation # ${JSON.stringify(
        searchCriteria
      )}`
    );
    try {
      setWaitingHeader("Loading conversations");
      setIsWaitingModalOpen(true);
      console.log(`User is Searching with ${JSON.stringify(searchCriteria)}`);
      console.log(
        `Search Criteria while preparing for the Request is ${JSON.stringify(
          searchCriteria
        )}`
      );
      if (role != "admin") {
      } else {
        console.log(`user is admin user hence no restrictions applied`);
      }

      console.log(
        `Search Criteria before submitting is ${JSON.stringify(searchCriteria)}`
      );
      const response = await backend.getIPFXConversations(
        sessionStorage.getItem(constants.GC_TOKEN),
        searchCriteria
      );
      console.log("fetchData.response:", JSON.stringify(response));
      if (response.records_returned === 0) {
        setSearchAlertVisible(true);
        setSearchAlertMessage(
          `No records returned. Please verify your search criteria.`
        );
      }
      if (response.records_returned < response.total_records) {
        setSearchAlertVisible(true);
        setSearchAlertMessage(
          `Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`
        );
      }

      setIpfxConversations(mapConversations(response.conversations));
      //sessionStorage.setItem(constants.R2S_CONVERSATIONS, JSON.stringify(response.conversations))
    } catch (error) {
      setError(
        `An error occured while fetching data:${JSON.stringify(error.message)}`
      );
      console.log(error);
    } finally {
      setIsWaitingModalOpen(false);
    }
  };
  const handleOnCellClick = (params) => {
    viewRecording(params);
  };

  const getValue = (params, label, value) => {
    if (
      label.toLowerCase().indexOf("start") > -1 ||
      label.toLowerCase().indexOf("end") > -1
    ) {
      return new Date(params.row[value]).toLocaleString();
    } else return params.row[value] ?? "";
  };
  const formatValue = (params, label, value) => {
    if (
      label.toLowerCase().indexOf("start") > -1 ||
      label.toLowerCase().indexOf("end") > -1
    ) {
      return new Date(params.value).toLocaleString();
    } else return params.value ?? "";
  };

  const includesIgnoreCase = (array, element) =>
    array.some(
      (e) => typeof e === "string" && e.toLowerCase() === element.toLowerCase()
    );

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    setSearchAlertVisible(true);
    setSearchAlertMessage(`Copied to clipboard.`);
  };

  useEffect(() => {
    console.log("Loading Home JS");

    const fetchSettings = async () => {
      try {
        setWaitingHeader("Loading conversation settings");
        setIsWaitingModalOpen(true);

        const response = await backend.getUsersMe(
          sessionStorage.getItem(constants.GC_TOKEN)
        );
        console.log(
          `Authorized Divisions are: ${JSON.stringify(
            response.user.authorizedDivisions
          )}`
        );

        if (
          !response.user.authorizedDivisions.some((division) =>
            ["all", "*"].includes(division.toLowerCase())
          )
        ) {
          const authorizedDivisionsLower =
            response.user.authorizedDivisions.map((division) =>
              division.toLowerCase()
            );

          setIPFXUser(authorizedDivisionsLower.includes("ipfx"));
          setVerintUser(
            authorizedDivisionsLower.some((division) => division !== "ipfx")
          );
        } else {
          setVerintUser(true);
          if (response.user.role.value.toLowerCase() === "admin") {
            setIPFXUser(true);
          }
        }

        sessionStorage.setItem(
          constants.R2S_USER_ROLE,
          response.user.role.value
        );
        setRole(response.user.role.value);
        setUser(response.user);

        setAgents(response.user.users);

        const conversationsColumns = response.user.conversationColumns.filter(
          (x) => x.visible
        );
        const columnsToDisplay = conversationsColumns.reduce(
          (acc, column) => ({
            ...acc,
            [column.value]: column.visible,
          }),
          {}
        );

        setColumnsToDisplay(columnsToDisplay);

        const columns = conversationsColumns.sort().map((x) => ({
          headerName: x.label,
          field: x.value,
          type: "string",
          minWidth: defaultWidthColumns,
          flex: x.label.toUpperCase().includes("CONTACT ID") ? 0 : 1,
          width: 175,
          sortable: true,
          //valueGetter: (params) => getValue(params, x.label, x.value),
          valueFormatter: (params) => formatValue(params, x.label, x.value),
          sortingOrder: ["desc", "asc"],
          filterOperators:
            x.label.toUpperCase().includes("START") ||
            x.label.toUpperCase().includes("END")
              ? dateOperators(true)
              : x.label.toUpperCase().includes("DURATION (IN SEC)")
              ? rangeOperator
              : [...getGridStringOperators(), notContainOperator],
          renderHeader: (params) => (
            <Typography
              component="div"
              variant="body2"
              style={{ whiteSpace: "normal" }}
            >
              {x.label.toUpperCase()}
            </Typography>
          ),
        }));

        columns.unshift({
          headerName: "ACTIONS",
          field: "details",
          type: "actions",
          hideable: false,
          getActions: (params) => [
            <Tooltip title="View Details" arrow>
              <GridActionsCellItem
                icon={<Info />}
                label="DETAILS"
                onClick={(event) =>
                  handleActionRowClick(params, event, "details")
                }
              />
            </Tooltip>,
            <Tooltip title="Share" arrow>
              <GridActionsCellItem
                icon={<ShareIcon />}
                label="SHARE"
                onClick={(event) =>
                  handleActionRowClick(params, event, "share")
                }
              />
            </Tooltip>,
            <Tooltip title="Download" arrow>
              <GridActionsCellItem
                icon={<DownloadIcon />}
                label="DOWNLOAD"
                onClick={(event) =>
                  handleActionRowClick(params, event, "download")
                }
              />
            </Tooltip>,
          ],
        });

        setColumns(columns);

        // Handle loading and setting conversations from sessionStorage
        const storedConversations = sessionStorage.getItem(
          constants.R2S_CONVERSATIONS
        );
        if (storedConversations) {
          const parsedConversations = JSON.parse(storedConversations);
          if (parsedConversations) {
            setConversations(parsedConversations);
          }
        }
      } catch (error) {
        setError(
          `An error occurred while fetching conversation settings: ${error.message}`
        );
        console.error(error);
      } finally {
        setIsWaitingModalOpen(false);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    // update columns show property
    console.log("columnsToDisplay.useEffect.trigger:", columnsToDisplay);
    console.log(` columns are ${columns}`);
    if (!columnsToDisplay || !columns || columns.length === 0) {
      console.log("columnsToDisplay.useEffect.exiting use effect");
      return;
    }
    console.log("columnsToDisplay.useEffect.processing use effect");
    for (const key in columnsToDisplay) {
      if (Object.hasOwnProperty.call(columnsToDisplay, key)) {
        columns.find((x) => x.accessor === key).show = columnsToDisplay[key];
      }
    }
    setColumns([...columns]);
    console.log(`column details ${JSON.stringify(columns)}`);
  }, [columnsToDisplay]);

  const DownloadReport = async () => {
    utils.JSONToCSVConvertor(
      conversations,
      `R2S_Search_results_${moment().format()}`,
      true
    );
  };

  const exportReport = async () => {
    let serachParams = JSON.parse(
      sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA)
    );
    exportConversation(serachParams);
  };

  const exportIPFXReport = async () => {
    let serachParams = JSON.parse(
      sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA)
    );
    exportIPFXConversation(serachParams);
  };

  const handleSearch = async (searchParams) => {
    console.log("getRecordings.searchCriteria:", searchParams);
    searchConversation(searchParams);
    // toggleConversationsSearchCriteria(false)
  };
  const handleSearchIPFX = async (searchParams) => {
    console.log("getRecordings.searchCriteria:", searchParams);
    searchIPFXConversations(searchParams);
    // toggleConversationsSearchCriteria(false)
  };
  const toggleColumnsSettings = () => {
    setIsConversationsSearchCriteriaOpen(false);
    setIsColumnsSettingsOpen(!isColumnsSettingsOpen);
  };
  const toggleConversationsSearchCriteria = () => {
    setIsColumnsSettingsOpen(false);
    setIsConversationsSearchCriteriaOpen(!isConversationsSearchCriteriaOpen);
  };

  const onDialogResponse = (response, fields) => {
    if (isMounted.current) {
    }
  };
  const viewRecording = React.useCallback(
    async (params) => {
      try {
        setWaitingHeader("Loading conversation details");
        setIsWaitingModalOpen(true);
        let conversationParam = params.row.conversation_id;
        if (params.row.recording_id) {
          conversationParam += "-" + params.row.recording_id;
        }

        let response = await backend.getConversation(
          sessionStorage.getItem(constants.GC_TOKEN),
          conversationParam
        );

        console.log("getConversation.response:", response);
        setConversation(response);
        setFields(response);
        if (response.recordings) {
          //response = await backend.getConversationMetadata(sessionStorage.getItem(constants.GC_TOKEN), id, response.recordings)
          let recordingList = [];
          recordingList.push(response.recordings);
          setRecordings(mapRecordings(recordingList));
          //setFields({...fields, recordings: mapRecordings(recordingList)});
        }
      } catch (error) {
        console.log("fetchConversation:,", error);
        setError(
          `An error occured while fetching the conversation:${JSON.stringify(
            error.message
          )}`
        );
      } finally {
        setViewRecordingModal(true);
        setIsWaitingModalOpen(false);
      }
    },
    [viewRecordingModal, conversation]
  );
  const handleRowClick = (params, event, details) => {
    viewRecording(params);
    //history.push(`/conversations/${params.row.conversation_id}/details`)
  };

   const handleIPFXRecordingDownload = async (
      params
    ) => {
      const recordingURL = params.row?.recording_file_path
      if (!recordingURL) {
        console.error("Recording URL is not provided.");
        return;
      }
  
      // Calling the API to get the recording URL status
      try {
        let usertoken = sessionStorage.getItem(constants.GC_TOKEN);
  
        let apiResponse = await backend.getRecordingURLStatus(
          usertoken,
          params.row?.ipfx_transaction_id,
          true
        );
        // Handle the apiResponse here
        console.log(apiResponse); // or any other logic you need
      } catch (error) {
        console.error("Error fetching recording URL status:", error);
      }
  
      try {
        // Fetching the file
        const response = await fetch(recordingURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
  
        // Creating a blob URL
        const blobUrl = window.URL.createObjectURL(blob);
  
        // Creating a temporary anchor element to download the blob
        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = blobUrl;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
  
        // Cleanup
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);
  
        console.log("Download initiated.");
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    };
  
  const fetchRecordingBlob = async () => {
    const response = await fetch(recordingURL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  };

  const downloadBlob = (blob, filename) => {
    const blobUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = blobUrl;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(anchor);
  };

  const updateRecordingStatus = async (conversation_id) => {
    const usertoken = sessionStorage.getItem(constants.GC_TOKEN);
    const apiResponse = await backend.getRecordingURLStatus(
      usertoken,
      conversation_id,
      true
    );
    console.log(apiResponse); // Handle the apiResponse here
  };

  useEffect(() => {
    const preFetchRecording = async () => {
      if (!recordingURL) return; // Check if recordingURL is not null

      try {
        const response = await fetch(recordingURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        setPreFetchedBlob(blob);
      } catch (error) {
        console.error("Error pre-fetching the file:", error);
      }
    };
    preFetchRecording();
  }, [recordingURL]);
    
  const handleVerintRecordingDownload = async (params) => {
    setIsDownloading(true);
    const recordingId = params?.row?.recording_id;
    const conversationId = params?.row?.conversation_id
    let response = await backend.getConversation(
      sessionStorage.getItem(constants.GC_TOKEN),
      conversationId
    );
    console.log("getConversation.response:", response);
    if (response.recordings) {
      let recordingList = [];
      recordingList.push(response.recordings);
      setRecordings(mapRecordings(recordingList));
      const recording = recordingList.find(r=>r.recording_id === recordingId);
      if (recording){
        try {
          setRecordingURL(recording.url);
          console.log(recording);
          const blob = preFetchedBlob ? preFetchedBlob : await fetchRecordingBlob();
          downloadBlob(blob, `${conversationId}.wav`);
        } catch (error) {
          console.error("Error during the download process:", error);
        } finally {
          setIsDownloading(false);    
          try {
            await updateRecordingStatus(conversationId);
          } catch (apiError) {
            console.error("Error updating recording status:", apiError);
          }
        }
      }else{
        console.log("recording not found")
      }
    }
  };
  const handleActionRowClick = (params, event, action) => {
    switch (action) {
      case "details":
        viewRecording(params);
        break;
      case "share":
        const shareLink = `${window.location.href}/${
          params.row.conversation_id
        }/details?environment=${sessionStorage.getItem(
          constants.GC_ENVIRONMENT
        )}`;
        return (
          <CopyToClipboard 
          text={shareLink}
          onCopy={onCopyText()}>
            <span>
              <CopyAllRounded />
            </span>
          </CopyToClipboard>
        );
      case "download":
        // Handle download action
        handleVerintRecordingDownload(params);
        break;
      default:
        break;
    }
  };

  const columnActions = (params) => {
    let results = [];
    const customActions = [];
    {
      customActions.push(
        <GridActionsCellItem
          key={params.row.id}
          icon={
            <React.Fragment>
              <Info />
            </React.Fragment>
          }
          label="Play"
          onClick={(event) => handleRecordingClick(params, event)}
        />        
      );
      customActions.push(
        <Tooltip title="Download" arrow>
          <GridActionsCellItem
            icon={<DownloadIcon />}
            label="DOWNLOAD"
            onClick={(event) =>
              handleIPFXRecordingDownload(params)
            }
          />
        </Tooltip>,
      )
    }

    const baseActions = [];
    results = [
      ...results,
      ...customActions.filter((f) => f !== null || f !== undefined),
      ...baseActions,
    ];
    return results;
  };
  const defaultWidthColumns = 175;
  const ipfxcolumns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "DETAILS",
        hideable: false,
        getActions: (params) => columnActions(params),
      },
      {
        headerName: "Start",
        field: "conversation_start_ts",
        width: defaultWidthColumns,
        minWidth: defaultWidthColumns,
        flex: 1,
        sortingOrder: ["desc", "asc"],
        renderHeader: (params) => (
          <Typography
            component="label"
            variant="body2"
            sx={{ whiteSpace: "normal" }}
          >
            CONVERSATION START
          </Typography>
        ),
        filterOperators: dateOperators(true),
        valueFormatter: (params) =>
          formatValue(params, "conversation_start_ts", "conversation_start_ts"),
      },
      {
        headerName: "End",
        field: "conversation_end_ts",
        width: defaultWidthColumns,
        minWidth: defaultWidthColumns,
        flex: 1,
        renderHeader: (params) => (
          <Typography
            component="label"
            variant="body2"
            sx={{ whiteSpace: "normal" }}
          >
            CONVERSATION END
          </Typography>
        ),
        filterOperators: dateOperators(true),
        valueGetter: (params) =>
          getValue(params, "conversation_end_ts", "conversation_end_ts"),
      },
      {
        field: "ipfx_transaction_id",
        headerName: "Transaction Id",
        type: "string",
        minWidth: defaultWidthColumns,
        flex: 1,
        width: defaultWidthColumns,
        filterOperators: [...getGridStringOperators(), notContainOperator],
        renderHeader: (params) => (
          <Typography
            component="label"
            variant="body2"
            sx={{ whiteSpace: "normal" }}
          >
            TRANSACTION ID
          </Typography>
        ),
      },

      {
        headerName: "Extension",
        field: "agent_extension",
        width: defaultWidthColumns,
        minWidth: defaultWidthColumns,
        flex: 1,
        filterOperators: [...getGridStringOperators(), notContainOperator],
        renderHeader: (params) => (
          <Typography
            component="label"
            variant="body2"
            sx={{ whiteSpace: "normal" }}
          >
            EXTENSION
          </Typography>
        ),
      },
      {
        headerName: "ANI",
        field: "caller_ani",
        width: defaultWidthColumns,
        minWidth: defaultWidthColumns,
        flex: 1,
        filterOperators: [...getGridStringOperators(), notContainOperator],
        renderHeader: (params) => (
          <Typography
            component="label"
            variant="body2"
            sx={{ whiteSpace: "normal" }}
          >
            ANI
          </Typography>
        ),
      },
      {
        headerName: "Direction",
        field: "direction",
        width: defaultWidthColumns,
        minWidth: defaultWidthColumns,
        flex: 1,
        filterOperators: [...getGridStringOperators(), notContainOperator],
        renderHeader: (params) => (
          <Typography
            component="label"
            variant="body2"
            sx={{ whiteSpace: "normal" }}
          >
            DIRECTION
          </Typography>
        ),
      },
    ],
    [columnActions]
  );

  const handleRecordingClick = (params) => {
    console.log("handleRecordingClick:", params);
    setRecordingPlayback(params.row);
    toggleRecordingPlaybackModal();
  };
  const toggleRecordingPlaybackModal = () =>
    setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen);

  return (
    <React.Fragment>
      <Header role={role} />
      <WaitingModal
        isOpen={isWaitingModalOpen}
        header={waitingHeader}
        onCancel={() => setIsWaitingModalOpen(false)}
      />
      <AlertModal
        isOpen={!!error}
        header="Error"
        toggle={() => {
          setError(undefined);
        }}
        body={error}
      />
      <ConfirmationModal
        isOpen={!!message}
        header="Success!"
        toggle={() => {
          setMessage(undefined);
        }}
        body={message}
      />
      {/*<Tooltip placement="bottom" isOpen={searchConversationsTooltipOpen}
                target="searchConversations"
                toggle={() => { setSearchConversationsTooltipOpen(!searchConversationsTooltipOpen) }}>
                Search conversations
    </Tooltip>*/}

      {/*<Tooltip placement="bottom" isOpen={downloadTooltipOpen}
                target="downloadReport"
                toggle={() => { setDownloadTooltipOpen(!downloadTooltipOpen) }}>
                Download report to CSV
            </Tooltip>*/}
      <div id="component-toolbar">
        {/*<button id="searchConversations" onClick={toggleConversationsSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>

                <button id="downloadReport"
                    disabled={conversations && conversations.length > 0 ? false : true}
                    onClick={DownloadReport}><FontAwesomeIcon className="fa-2x" icon={faDownload} />
                </button>*/}
      </div>
      <AlertMessage
        message={searchAlertMessage}
        visible={searchAlertVisible}
        setVisible={(x) => {
          setSearchAlertVisible(x);
        }}
      />
      <Box sx={{ width: "100%", p: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {isVerintUser && <Tab label="Verint" {...a11yProps(0)} />}
            {isIPFXUser && (
              <Tab label="IPFX" {...a11yProps(isVerintUser ? 1 : 0)} />
            )}
          </Tabs>
        </Box>
        {isVerintUser && (
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ width: "100%" }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 2, md: 2 }}>
                <Grid item xs={12} md={12}>
                  {user && (
                    <ConversationsSearchCriteria
                      search={handleSearch}
                      cancel={() => {
                        toggleConversationsSearchCriteria(false);
                      }}
                      divisions={
                        user.divisions
                          ? user.divisions.filter((x) => x.value !== "*")
                          : []
                      }
                      role={user.role ? user.role.value : undefined}
                      agents={
                        agents ? agents.filter((x) => x.value !== "*") : []
                      }
                      convDirection={
                        user.convDirection
                          ? user.convDirection.filter((x) => x.value !== "*")
                          : []
                      }
                      organization={
                        user.organization
                          ? user.organization.filter((x) => x.value !== "*")
                          : []
                      }
                      datasource={
                        user.datasource
                          ? user.datasource.filter((x) => x.value !== "*")
                          : []
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <RecordingDialog
                    dialogModal={viewRecordingModal}
                    dialogSetter={setViewRecordingModal}
                    dialogFields={fields}
                    dialogFieldsSetter={setFields}
                    onDialogResponse={onDialogResponse}
                  />

                  <DataGrid
                    components={{
                      Toolbar: TableToolbar,
                    }}
                    //componentsProps={{ toolbar: { downloadAction: ()=> DownloadReport(),disabled: conversations && conversations.length > 0 ? false : true}}}
                    componentsProps={{
                      toolbar: {
                        downloadAction: () => exportReport(),
                        showDownload: role === "admin",
                        disableDownload:
                          conversations && conversations.length > 0
                            ? false
                            : true,
                      },
                    }}
                    sx={{
                      ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                        {
                          "margin-top": "1em",
                          "margin-bottom": "1em",
                        },
                    }}
                    apiRef={gridRef}
                    key={tablePrefId}
                    autoHeight
                    pageSizeOptions={[25, 50, 75, 100]}
                    rows={conversations}
                    columns={columns}
                    localeText={{
                      filterOperatorNoContain: "not contain",
                    }}
                    state={{
                      preferencePanel: {
                        open: true,
                        openedPanelValue: GridPreferencePanelsValue.filters,
                      },
                    }}
                    getRowId={(row) => row.id}
                    getRowHeight={() => "auto"}
                    showCellVerticalBorder
                    sortingOrder={["desc", "asc"]}
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: "conversation_start_ts",
                            sort: "asc",
                          },
                        ],
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </CustomTabPanel>
        )}
        {isIPFXUser && (
          <CustomTabPanel value={value} index={isVerintUser ? 1 : 0}>
            <Box>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 2, md: 2 }}>
                <Grid item xs={12} md={12}>
                  <IpfxRecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => {
                      toggleRecordingPlaybackModal();
                    }}
                    recording={recordingPlayback}
                  />
                  {user && (
                    <IPFXConversationsSearchCriteria
                      search={handleSearchIPFX}
                      role={user.role ? user.role.value : undefined}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={12}></Grid>
                <Grid item xs={12} md={12}>
                  <RecordingDialog
                    dialogModal={viewRecordingModal}
                    dialogSetter={setViewRecordingModal}
                    dialogFields={fields}
                    dialogFieldsSetter={setFields}
                    onDialogResponse={onDialogResponse}
                  />
                  <DataGrid
                    components={{
                      Toolbar: TableToolbar,
                    }}
                    // componentsProps={{ toolbar: { downloadAction: ()=> DownloadReport(), disabled: ipfxConversations && ipfxConversations.length > 0 ? false : true}}}
                    componentsProps={{
                      toolbar: {
                        downloadAction: () => exportIPFXReport(),
                        showDownload: role === "admin",
                        disableDownload:
                          ipfxConversations && ipfxConversations.length > 0
                            ? false
                            : true,
                      },
                    }}
                    sx={{
                      ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                        {
                          "margin-top": "1em",
                          "margin-bottom": "1em",
                        },
                    }}
                    apiRef={ipfxGridRef}
                    key={ipfxTablePrefId}
                    autoHeight
                    pageSizeOptions={[25, 50, 75, 100]}
                    rows={ipfxConversations}
                    columns={ipfxcolumns}
                    localeText={{
                      filterOperatorNoContain: "not contain",
                    }}
                    state={{
                      preferencePanel: {
                        open: true,
                        openedPanelValue: GridPreferencePanelsValue.filters,
                      },
                    }}
                    getRowId={(row) => row.id}
                    getRowHeight={() => "auto"}
                    showCellVerticalBorder
                    sortingOrder={["desc", "asc"]}
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: "conversation_start_ts",
                            sort: "asc",
                          },
                        ],
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </CustomTabPanel>
        )}
      </Box>

      <Footer role={role} />
    </React.Fragment>
  );
};

export default Home;
